<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
//   chartdata: {
//       labels: ['January', 'February'],
//       datasets: [
//         {
//           label: 'Data One',
//           backgroundColor: '#f87979',
//           data: [40, 20]
//         }
//       ]
//     },
//     options: {
//       responsive: true,
//       maintainAspectRatio: false
//     },
  props: ['chartdata', 'options'],
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  watch:{
      chartdata: {
          deep: true,
          handler: function(){
              this.renderChart(this.chartdata, this.options)
          }
      }
  }
}
</script>

<style>
</style>